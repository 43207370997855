.home_container  {
    .carousel {
        height: calc(100vh - 224px);
        &:hover {
            .carousel-caption {
                bottom: 0;
                transition: bottom 0.5s ease-out;
            }
        }
        .carousel_img {
            width: 100%;
            height: calc(100vh - 224px);
            object-fit: cover;
        }
        
        
        .carousel-caption {
            left: 0;
            right: 0;
            transition: bottom 1s ease-out;
            padding: 50px 50px 50px 50px;
            width: 100%;
            background: rgba(0, 0, 0, 0.7);
            p, h2 {
                color: #fff;
            }
        }
    }
    
}

@media screen and (min-width: 728px) {
    .home_container  {
        .carousel {
            .carousel-caption {
                bottom: -100%;
            }
        }
    }
}
@media screen and (max-width: 992px) {
    .home_container {
        .carousel {
            height: calc(100vh - 204px);
            .carousel_img {
                height: calc(100vh - 204px);
            }
        }
    }
}
@media screen and (max-width: 728px) {
    .home_container {
        .carousel {
            height: calc(100vh - 174px);
            .carousel_img {
                height: calc(100vh - 174px);
            }
            .carousel-caption {
                bottom: 0%;
            }
        }
    }
}