@import './colors.scss';
@import "bootstrap";

/* ------- customize danger button color -------- */   

.btn-danger {
    @include button-variant($red, darken($red, 7.5%), darken($red, 0%), lighten($red,5%), lighten($red, 10%), darken($red,30%));
    &,
    &:hover,
    &:active,
    &:focus {
        color: white;
    }
}
.btn-outline-danger {
    @include button-outline-variant($red, #222222, lighten($red,5%), $red);
}

body {
    background: $gray;
}
.container {
    max-width: 1900px;
    padding-top: 50px;
    padding-bottom: 50px;
}

.pageTitle {
    display: inline-block;
    padding: 7px 30px;
    text-transform: uppercase;
    font-weight: bold;
    font-style: italic;
    background: $orange;
    color: white;
    margin-bottom: 30px;
    max-width: 80%;
}
@media screen and (min-width: 729px) {
    .hide_on_desktop {
        display: none!important;
    }
}
@media screen and (max-width: 728px) {
    .container {
        padding-top: 20px;
        padding-bottom: 40px;
    }
    .pageTitle {
        margin-bottom: 20px;
        max-width: 100%;
    }
    .hide_on_mobile {
        display: none!important;
    }
}