.map_container {
    width: 100%;
    height: 500px;
    box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.7);
    margin: 50px 0 0 0;
}
.about_contacts_list {
    padding: 0;
    margin: 0;
    li {
        list-style: none;

    }
}