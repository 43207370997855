.pagination_container {
    display: flex;
    gap: 10px;
    .product_list_pagination {
        padding: 0;
        margin: 0;
        display: flex;
        gap: 10px;
        align-items: center;
        list-style: none;
        li {
            button {
                cursor: default;
                border: 0px;
                &.active {
                    color: #000;
                    font-weight: bold;
                    border-bottom: 3px solid #000;
                }
            }
        }
    }
    .pagination_nav_btn {
        outline-color: black;
        outline-offset: 0;
        border: 2px solid black;
    }
}