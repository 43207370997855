@import '../../styles/colors.scss';
.categoryHeader {
    text-align: left;
    text-transform: capitalize;
    font-style: italic;
}
.productList {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    list-style: none;
    padding: 0;
}

.load_more.btn-outline-primary,
.load_more.btn-outline-primary:active,
.load_more.btn-outline-primary:visited {
    border-color: black;
    color: black;
}
.load_more.btn-outline-primary:hover,
.load_more.btn-outline-primary:focus {
    background-color: black;
    border-color: black;
    color: white;
}


@media screen and (max-width: 992px) {
    .productList {
        grid-template-columns: repeat(3, 1fr)!important;
    }
}
@media screen and (max-width: 768px) {
    .productList {
        grid-template-columns: repeat(2, 1fr)!important;
    }
}