@import '../../styles/colors.scss';

.compare_widget_wrap {
    position: fixed;
    top: 10px;
    right: 10px;
    padding: 10px 10px;
    background: $orange;
    z-index: 100;
    border-radius: 10px;
    .compare_widget_top {
        display: flex;
        justify-content: end;
        button {
            line-height: 0;
            padding: 10px 10px 12px 10px;
            text-align: center;
            img {
                width: 30px;
                height: 30px;
            }
        }
    }
    .compare_widget_heading {
        display: inline-block;
        color: #000;
        font-weight: bold;
        margin-bottom: 5px;
    }
    .compare_list {
        flex-direction: column;
        .compare_widget_item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            button {
                padding: 5px 10px;
                background: transparent;
                border: none;
                svg {
                    width: 20px;
                    height: 20px;
                }
                &:hover {
                    svg {
                        color: #fff;
                    }
                }
            }
        }
        .compare_link {
            color: #000;
            &:hover {
                color: #fff;
            }
        }
    }
    &.hide {
        padding: 0;
        background: transparent;
        .compare_widget_heading,
        .compare_list,
        a.load_more {
            display: none!important;
        }
        .compare_widget_top {
            button {
                background: $orange;
            }
        }
    }
}
@media screen and (max-width: 992px) {
    .compare_widget_wrap {
        position: static;
        border-radius: 0px 0px 10px 10px;
        .compare_widget_top {
            display: none;
        } 
    }
}
@media screen and (max-width: 778px) {
    .compare_widget_wrap {
        .compare_list {
            .compare_link {
                padding: 5px;
                &:hover {
                    color: #fff;
                }
            }
        }
    }
}