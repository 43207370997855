.product_container {
    .card {
        padding: 30px;
    }
    .product_price {
        font-weight: bold;
        font-size: 22px
    }
    .product_content_left {
        padding-right: 40px;
    }
    .product_info_row {
        text-align: left;
        div {
            &:nth-child(2) {
                font-weight: bold;
            }
        }
        
    }
    .add_to_cart_button {
        margin-top: 20px;
    }
    .product_description {
        margin-top: 30px;
        border-top: 2px solid #d3d3d3;
        padding: 20px;
        text-align: left;
    }
    h2 {
        text-align: left;
        margin: 10px 0;
    }
    .carousel_img_button {
        border: none;
        background: #fff;
    }
    .carousel_img {
        object-fit: contain;
        width: 100%;
        height: 600px;
    }
    .carousel.slide {
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
    }
    .product_buttons_row {
        padding: 0px 10px;
    }
    .modal_image_container {
        top: 0;
        left: 0;
        position: fixed;
        width: 100%;
        height: 100vh;
        background: rgba(0,0,0,0.7);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 100;
        .modal_close_button {
            background: #000;
            position: absolute;
            border-radius: 5px;
            right: 20px;
            top: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            width: 40px;
            height: 40px;
            z-index: 102;
        }
        .modal_image {
            opacity: 1;
            z-index: 101;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

@media screen and (max-width: 728px) {
    .product_container {
        h2 {
            font-size: 20px;
        }
        .card {
            padding: 20px
        }
        .product_content_wrap {
            padding: 0 10px;
        }
        .product_price {
            margin-top: 0;
            font-size: 20px;
        }
        
        .product_description {
            margin-top: 20px;
            padding: 5px 0 0 0;
        }
        .carousel_img {
           max-height: 400px;
        }
    }
}