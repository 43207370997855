@import '../../styles/colors.scss';
.navbar {
    flex-direction: column;
    background: $black;

    .navbar_logotype {
        font-weight: bold;
        text-decoration: none;
        .navbar-brand {
            color: #fff;
        }
        
    }
    .navbar_logotype_mobile {
        display: none;
    }
    
    .nav_container {
        display: flex;
        justify-content: center;
        gap: 100px;
        align-items: center;
        padding: 30px 50px 0;
        width: 100%;
        .wrap_special_nav_items {
            gap: 20px;
        }
    }
    .navbar-collapse {
        justify-content: center;
    }
    .navbar_toggler_button {
        svg {
            color: white;
            width: 40px;
            height: 40px;
        }
    }
    .nav-item.show {
        .nav-link.show {
            color: #d3d3d3;
        }
    }
    .nav-link {
        position: relative;
        color: #fff;
        &:hover,
        &:active,
        &:focus {
            color: $gray;
        }
        &.nav-item-with_bubble {
            padding-right: 25px;
            .bubble {
                width: 22px;
                height: 22px;
                font-size: 12px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                background: $red;
                position: absolute;
                right: 2px;
                top: 0px;
                color: #fff
            }
        }
        
    }
    .categoryDropdown {
        .sub_category_item {
             color: $black;
        }
        // background: #FFDB89;
        .subCategoryLink{
            display: block;
            padding: 5px 15px;
        }
    }
}
@media screen and (max-width: 992px) {
    .navbar {
        .nav_container {
            gap: 50px;
        }
        .navbar_logotype_desktop {
            display: none;
        }
        .navbar_logotype_mobile {
            display: block;
        }
        .dropdown-menu  {
            padding: 0 10px;
        }
        .nav-link {
            &.nav-item-with_bubble {
                display: flex;
                justify-content: center;
                align-items: center;
                .bubble {
                    position: static;
                    margin-left: 10px;
                }
            }
        }
    }
}
@media screen and (max-width: 992px) {
    .navbar {
        .container {
            padding-bottom: 20px;
        }
    }
}